.page-loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  pointer-events: none;
  background-color: #3a3b3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-loader-back {
  height: 7rem;
  width: 7rem;
  object-fit: contain;
  position: absolute;
  animation: rotating 1.5s ease-in-out infinite;
}

img:-moz-loading {
  visibility: hidden;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-loader-front {
  height: 7rem;
  width: 7rem;
  object-fit: contain;
  position: absolute;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
